/*jshint esversion: 6 */ 

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel';
import './recapcha-load-on-demand';					// Load Google Recapcha on demand

// Content loaded directly from WP Plugins. Match this with "disable_scripts_styles".
//import '../../../../plugins/contact-form-7/includes/css/styles.css';
//import '../../../../plugins/contact-form-7/includes/js/scripts.js';
//import '../../../../plugins/contact-form-7/includes/js/index.js';
//import '../../../../plugins/jquery-validation-for-contact-form-7/includes/assets/js/jquery.validate.min.js';
//import '../../../../plugins/contact-form-7/includes/js/html5-fallback.js';

import Cookies from 'js-cookie';
import '../styles/index.scss';

/*global smStylesheetCDN */


// Expose JS modules via global scope
window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;


// Load the lazysizes plugin if required
if (!('loading' in HTMLImageElement.prototype)) {
	let s = document.createElement('script');
	s.src = smStylesheetCDN + '/js/lazysizes.min.js';
	s.async = true;
	document.body.appendChild(s);
}

jQuery(function($) {
	let useGA, useYT/*, usePardot*/;
	
	
	// Prevent the focus outline being displayed to mouse and mobile users
	function handleFirstTab(e) {
		if (e.keyCode === 9) {
			document.body.classList.add('user-is-tabbing');
			window.removeEventListener('keydown', handleFirstTab);
		}
	}
	window.addEventListener('keydown', handleFirstTab);
	document.body.classList.remove('user-is-tabbing');
	

	// Enquiry form on many pages such as "Get In Touch" and "About Us"
	document.addEventListener('wpcf7submit', function(e) {
		let status = e.detail.status;

		let success = status==='mail_sent';
		
		// On success or failure, display an error until the user clicks the close button.
		if (success || status!=='validation_failed') {
			let $enquiryForm = $(e.target).closest('.enquiry-form');
			let $responseForm = $enquiryForm.find('.response-form');
			let $siteBtn = $responseForm.find('.siteBtn').text(success ? 'SEND ANOTHER' : 'TRY AGAIN');
			
//			let pardotURL = $enquiryForm.find('input[name="hidden-pardot"]').val();
//			let userEmail = $enquiryForm.find('input[name="email"]').val();
			
			$responseForm.find('h3').text(success ? 'Success!' : 'Failed!');
			$responseForm.toggleClass('success', success).fadeIn();
			$siteBtn.focus();
			
			// Close the message
			$siteBtn.one('click', function(e) {
				e.preventDefault();

				if (success)
					$responseForm.trigger('reset');

				$responseForm.hide();
				$enquiryForm.find('input[type="submit"]').focus();
			});
			
/*			// After a sucessfull post, inform Pardot so it can link the form submission to the browser session
			if (success && usePardot && pardotURL && userEmail) {
				$('<iframe src="'+pardotURL+'?Email='+encodeURIComponent(userEmail)+'" frameborder="0" scrolling="no" width="0" height="0"></iframe>').appendTo(body);
			}*/
		}
	}, false );

/*	function getParameterByName(name) {
		let match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	}*/
	
	// Post-scroller
	$('.sm-post-scroller').each((i, el) => {
		let $scroller = $(el);
		let $posts = $scroller.find('.sm-post-scroller__posts');
		let $loadBtn = $scroller.find('.sm-post-scroller__btns a');
		let $catBtns = $scroller.find('.sm-post-scroller__cats a');
		let postType = $scroller.attr('data-type');
		let exclude = $scroller.attr('data-exclude');
		let cats = $scroller.attr('data-cats');

/*		// Select the category button based on an optional URL parameter "cat"
		if ($catBtns.length) {
			let preSelectCat = getParameterByName('cat');
			if (preSelectCat)
				selectCat($catBtns.filter('[data-cat="' + preSelectCat + '"]'));
		}
*/
/*		// Blog Categories selector
		$catBtns.click(function(e) {
			e.preventDefault();
			selectCat(this);
			loadPosts(true);
		});*/

		// Blog Load More button clicked
		$loadBtn.click(function(e) {
			e.preventDefault();
			loadPosts(false);
		});
		
/*		function selectCat(cat) {
			$catBtns.removeClass('active');
			$(cat).addClass('active');
		}*/
		
		function loadPosts(clear) {
			// Don't allow double loading
			if ($scroller.hasClass('loading'))
				return;

			let catIds = $catBtns.length ? $catBtns.filter('.active').attr('data-cat') : cats;
			let offset = clear ? 0 : $posts.children().length;

			// Display a loading message
			loading(true);

			$.ajax({
				url: ajaxurl,
				type: 'post',
				data : { action:'smLoadPost', offset:offset, type:postType, exclude:exclude, cats:catIds },
				success: function(r) {
					let $newPosts = $(r);
					lazyLoadImages($newPosts);
					
					if (clear)
						$posts.empty();

					$posts.append($newPosts);

					// Any more to load?
					let newCount = $posts.children().length;
					$loadBtn.toggle(newCount-offset >= 6);		// Loads 6 posts per call
				},
				complete: function() {
					loading(false);
				}
			});
		}
		
		function loading(isLoading) {
			$scroller.toggleClass('loading', isLoading);
			$loadBtn.toggleClass('disabled', isLoading);
		}
	});

	// Render the scroll arrows. Each anchor navigates to the next target section. Arrows to nowhere are not displayed.
	setTimeout(function() {														// Delay to allow the page to load in case this is slow
		let $prevA;
		$('.scroll-anchor, .scroll-target').each((i, el) => {					// Find the anchors and targets and return them in order
			let $el = $(el);
			if ($el.hasClass('scroll-anchor')) {
				if (!$prevA)
					$prevA = $el;												// Remember the first anchor and ignore the rest until the next target
			} else {
				if ($prevA) {
					// Create a target id if required
					let targetId = $el.attr('id');
					if (!targetId)
						$el.attr('id', (targetId = ('scroll-target__'+i)));
					
					$prevA.attr('href', '#'+targetId);							// Hook up the anchor
					$prevA.click(clickScrollAnchor);							// Enable smooth scrolling
					$prevA.parent().addClass('show');
					
					$prevA = null;
				}
			}
		});
	}, 100);
	
	
	function clickScrollAnchor(e) {
		e.preventDefault();
		let $src = $($(this).attr('href'));
		$('body,html').animate({
			scrollTop: $src.offset().top
		}, 800);
    }	

	const FOCUS_TAGS = 'a,input,select,textarea,button,object';
	
	// Main menu navigation handler
	(function() {
		let menuCloseTimer;
		let menuKeyboard;
		let blurStart;
		let blurTimer;

		$('.navdesktop__menu')
			// Mouse events for menu menu buttons and sub-menus
			.hover(showMenu, startMenuTimer)

			// Close the menu if the focus is lost			
			.find(FOCUS_TAGS).blur(function() {
				stopFocusCheck();
				blurStart = new Date().getTime();
				blurTimer = setInterval(function() {	// Repeatedly check as the focus first moves to the body before the new element
					if ( (!document.activeElement || document.activeElement.tagName==='BODY') && ((new Date().getTime())-blurStart<500) )
						return;							// Check again later

					toggleMenu(document.activeElement);
				}, 20 );
			});

		// When the menu is already open, a change in focus to a new menu item will open the new menu.
		$('.navdesktop__menu > a').focus(function() {
			showMenu.call(this);
		});

		// Close other menus when hovering over non-menu buttons
		$('#nav__open, #nav__git, #nav__search')
			.hover(showMenu, startMenuTimer)
			.focus(showMenu);

		$('.navdesktop__menu.navdesktop-children')
			// Hover and clicks on primary menu items, including dropdowns (with and without icons) and plain links.
			.keydown(function(e) {
				if (!e.isDefaultPrevented()) {
					if (e.which === 13) {
						// Open the menu with the Enter key
						e.preventDefault();
						menuKeyboard = true;			// Prevent mouseout from closing the window
						toggleMenu(this);

						// focus on the first element
						const $this = $(this);
						setTimeout(function() {
							focusOnFirst($this.find('.navdesktop__branded, .navdesktop__submenu').find(FOCUS_TAGS));
						}, 200);
					} else if (e.which === 27) {
						// Escape. Close the menu.
						e.preventDefault();
						closeMenu();					// Close the menu even though the main-menu has focus
					}
				}
			})

			// Click on main menu buttons
			.children('a').click(function(e) {
				e.preventDefault();
				e.stopPropagation();				// Eat the event so the body handler doesn't close the menu
				menuKeyboard = true;				// Prevent mouseout from closing the window
				toggleMenu(this);
			});

		// Close the menu immediately if the user clicks off the menu
		$('body').click(closeMenu);
		
		// Escape when the menu is open
		$('.navdesktop__branded, .navdesktop__subitem').keydown(function(e) {
			if (e.which === 27) {
				// Escape. Close the menu.
				e.preventDefault();

				// Move the focus back to the page.
				$(this).closest('.navdesktop__menu').children('a').focus();

				// Close the menu even though the main-menu has focus
				stopCloseTimer();
				stopFocusCheck();

				$('.navdesktop__menu').each(function() {
					let $this = $(this);
					$this.removeClass('navdesktop--showmenu');
					if ($this.hasClass('navdesktop--showmenu'))
						$this.attr('aria-expanded', false);
				});
			}
		});

		// Stop checking for lost focus
		function stopFocusCheck() {
			if (blurTimer) {
				clearInterval(blurTimer);
				blurTimer = undefined;
			}
		}

		// Close the menu after the mouse leaves the menu area
		function startMenuTimer(e) {
			if (!menuKeyboard) {
				stopCloseTimer();
				menuCloseTimer = setTimeout(closeMenu, 100);
			}
		}
		
		// Cancel the close menu timer
		function stopCloseTimer() {
			if (menuCloseTimer) {
				clearTimeout(menuCloseTimer);
				menuCloseTimer = undefined;
			}
		}

		// Display the menu
		function showMenu() {
			toggleMenu(this);
		}

		function closeMenu() {
			menuKeyboard = false;
			toggleMenu(-1);
		}

		// Show or hide a menu. Supply the menu position or -1 to close the menu.
		function toggleMenu(position) {
			stopCloseTimer();
			stopFocusCheck();

			// Lookup the menu position from the supplied child element
			if (typeof position !== 'number')
				position = position ? $(position).closest('.navdesktop__menu').index() : -1;

			let $menuWithFocus = document.activeElement && $(document.activeElement).closest('.navdesktop__menu');

			// Only show the menu and close the old one if the user does not have focus inside another open menu
			if (!$menuWithFocus || $menuWithFocus.length===0 || $menuWithFocus.index()===position) {
				// Enable the primary menu link and display the sub-menu
				$('.navdesktop__menu').each(function(i) {
					const show = i===position,
						$this = $(this);
					$this.toggleClass('navdesktop--showmenu', show);
					if ($this.hasClass('navdesktop--showmenu'))
						$this.attr('aria-expanded', show);
				});
			}
		}
	})();
	
	// Return true if the provided element can be tabbed to
	function focussable($el) {
		const element = $el[0];
		const tabIndex = $el.attr('tabindex');
		const hasTabindex = tabIndex != null;

		if (!hasTabindex || tabIndex >= 0) {
			const nodeName = element.nodeName.toLowerCase();
			return /^(input|select|textarea|button|object)$/.test(nodeName) ? !element.disabled : ('a' === nodeName) ? element.href || hasTabindex : hasTabindex;
		}

		return false;
	}
	
	function focusOnFirst($els) {
		$els.each((index, element) => {
			const $el = $(element);
			if (focussable($el)) {
				$el.focus();
				return false;
			}
		});
	}


// TODO: Activate handler on open?

	// Mobile menu navigation handler
	const closeMobileMenu = (function() {
		const $navopen = $('#nav__open');
		const $navmobile = $('#navmobile');
		const $close = $('#navmobile__close');
		
		$navopen.click(function(e) {
			if (!e.isDefaultPrevented()) {		// Ignore submenu clicks
				e.preventDefault();
				$navopen.attr('aria-haspopup', true);
				$navmobile.addClass('navmobile__showmenu').animate({right:0}, 300, 'swing', function(){
					$close.focus();		// Set the focus after scrolling to prevent iPhone from scrolling the view
				});
			}
		});
		
		$close.click(closeMenu);

		// Close on clicks outside the menu
		$('body').click(function (e) {
			if (!e.isDefaultPrevented() && $navmobile.hasClass('navmobile__showmenu'))
				closeMobileMenu();
		});

		// Prevent focus being set on click
		$('.navmobile__menu-children').mousedown(function(e) {
			e.preventDefault();
		});

		// Expand or collpse sub-menus
		$('.navmobile__menu-children > a').click(function(e) {
			e.preventDefault();
			const $expand = $(this).parent();
			const isOpen = $expand.hasClass('navmobile__menu--expand');
			$('.navmobile__menu-children').removeClass('navmobile__menu--expand');
			$expand.toggleClass('navmobile__menu--expand', !isOpen);
		});
		
		$navmobile.keydown(function(e) {
			if (e.which === 9) {
				trapFocus(e, $navmobile);
			} else if (e.which === 27) {
				// Escape. Close the menu.
				closeMenu(e);
			}
		});
		
		function closeMenu(e) {
			e.preventDefault();
			closeMobileMenu();
		}
		
		function closeMobileMenu() {
			$navmobile.animate({right:-300}, 300, function(){ $navmobile.removeClass('navmobile__showmenu'); });
			$navopen.attr('aria-haspopup', false).focus();
		}
		
		return closeMobileMenu;
	})();



	// Popup site search
	(function() {
		let searchDelay;
		let $list, $input, $submit;

		// Click or press on search icon to open the search form
		$('#nav__search, #navmobile__search').click(open).keydown(open);
		
		function open(e) {
			const openedWithKey = e.type==='keydown';
			if (openedWithKey && e.which!==13)
				return;

			e.preventDefault();
			
			$list = $('.search-modal__results');
			$input = $('.search-modal input');
			$submit = $('.search-modal button[type="submit"]');

			// Search on input change, pressing enter, or clicking on the submit button.
			$input.on('change input keypress', searchOnInput);
			$submit.on('click', searchOnClick);
			
			// Just in case the mobile menu is open
			closeMobileMenu();

			loadModal('.search-modal', function() {
				stopDelay();									// Cancel the timeout
				$list.html('');									// Clear the existing search results
				$list = undefined;								// Prevent the list being populated after closure

				$input.off('change input keypress', searchOnInput);
				$submit.off('click', searchOnClick);

				// If the search was opened with the keyboard, return the focus to the search button.
				// If the search was opened with the mouse, return the focus to the window.
				if (openedWithKey && $('#navmobile__search:visible').length)
					$('#navmobile__search').focus();
				else if (openedWithKey && $('#nav__search:visible').length)
					$('#nav__search').focus();
				else if (document.activeElement)
					document.activeElement.blur();
			});
		}
		
		function searchOnInput(e) {
			const enter = e.type==='keypress' && e.which===13;
			if (enter)
				e.preventDefault();
			doSearch(enter);		// Pressing Enter results in immediate search
		}

		function searchOnClick(e) {
			e.preventDefault();
			doSearch(true);
		}

		function doSearch(immediate) {
			stopDelay();

			let s = $input.val().trim();
			if (s.length < 3) {
				$list.html('');
				return;
			}

			if (immediate)
				runSearch();
			else
				searchDelay = setTimeout(runSearch, 700);

			function runSearch() {
				$.ajax({
					url:	ajaxurl,
					type:	'post',
					data:	{ action: 'data_fetch', keyword: s },
					success: function(data) {
						if ($list)
							$list.html(data);
					}
				});
			}
		}
		
		function stopDelay() {
			if (searchDelay !== undefined) {
				clearTimeout(searchDelay);
				searchDelay = undefined;
			}
		}
	})();


	// Display a modal dialog. Call the option onClose function when closing.
	// Returns an function so the caller can close the dialog.
	function loadModal(el, onClose) {
		let $el = $(el);
		$el.fadeIn().attr('aria-hidden', false);						// Display the dialog
		$el.on('keydown', FOCUS_TAGS, trapModalFocus);					// Trap the focus inside the dialog until it is closed
		$el.find('.custom-modal__close').on('click', closeModalEvent);	// Handle click on the close button
		$el.find('.custom-modal__focus').focus();						// Set the focus to a specific element
		return closeModal;

		// Close the modal dialog in response to an event
		function closeModalEvent(e) {
			e.preventDefault();
			closeModal();
		}
			
		function closeModal() {
			$el.find('.custom-modal__close').off('click', closeModalEvent);
			$el.off('keydown', FOCUS_TAGS, trapModalFocus);
			$el.fadeOut().attr('aria-hidden', true);

			if (onClose)
				onClose($el);
		}

		function trapModalFocus(e) {
			if (e.which === 9) {
				// Tab. Keep the focus inside the dialog.
				trapFocus(e, $el);
			} else if (e.which === 27) {
				// Escape. Close the form.
				closeModalEvent(e);
			}
		}
	}
	
	// Trap the focus within a range of elements in response to tab keypress
	function trapFocus(e, $outer) {
		let $tags = $outer.find(FOCUS_TAGS).filter(function(i, el) {		// Get all focussable elements
			return focussable($(el));
		});

		if ($tags.length) {
			let elFrom = e.shiftKey ? $tags[0] : $tags[$tags.length - 1];
			let elTo = e.shiftKey ? $tags[$tags.length - 1] : $tags[0];

			if (elFrom === document.activeElement) {
				elTo.focus();
				e.preventDefault();
			}
		}
	}


	// Auto-initialise slick sliders
	setTimeout(function() {											// Delay to allow the page to load as this is slow
		$('.slick-slider.sm-init').slick({
			prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
			nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>'
		});
	}, 100 );


	// Cookie management via Cookie Banner and Cookie Settings Popup.
	(function() {
		let cookieSettingsLoaded, $cookieGA, $cookieYT, /*$cookiePardot,*/ $cookieAccept, $cookieSave;

		// Cookie control initialisation
		let policyCookie = getCookiePolicy();
		let $cookieBanner = $('.cookie-notice');
		if (!policyCookie /*|| policyCookie.pardot===undefined*/)		// Old acceptance will be missing the Pardot permission
			$cookieBanner.css('display', 'block');

		// Load using a policy, or a default of 'no' before the user makes a decision
		enforceCookiePolicy(policyCookie);
		
		// "Accept" clicked
		$cookieBanner.find('button').click(function() {
			// If shown, hide the cookie notice banner or the pop-up.
			$cookieBanner.fadeOut();

			enforceCookiePolicy(saveCookiePolicy(true, true/*, true*/));
			return false;
		});	

		// Display the cookie setting popup form. Activated from the cookie banner and the /Cookies page.
		$('.cookiePopupBtn').click(function() {
			let closeCookiePopup;

			// Hide the banner while the popup is open
			$cookieBanner.fadeOut();

			// Load the cookie settings form once
			if (!cookieSettingsLoaded) {
				let $cookiePopup = $('<div class="cookie-modal custom-modal" role="dialog" aria-hidden="true"><div class="cookie-modal__widget"><div class="cookie-modal__loader">Loading...</div></div></div>')
					.hide().appendTo('body');		// 'hide' first so jQuery caches the flex style

				// Display the cookie form with the "Loading" message
				closeCookiePopup = loadModal('.cookie-modal', popupClosed);

				// Load the cookie form content, replacing the "Loading" message.
				$('.cookie-modal__widget').load('/cookiepopup .cookie-popup-page', null, function() {
					cookieSettingsLoaded = true;

					$cookieGA = $cookiePopup.find('a.cookiesGA');
					$cookieYT = $cookiePopup.find('a.cookiesYT');
//					$cookiePardot = $cookiePopup.find('a.cookiesPardot');
					$cookieAccept = $cookiePopup.find('a.cookiesAccept');
					$cookieSave = $cookiePopup.find('a.cookiesSave');

					// Toggle the cookie selection control
					$cookieGA.click(onToggle);
					$cookieYT.click(onToggle);
//					$cookiePardot.click(onToggle);

					// Ignore clicks on the 'essentials' cookie
					$cookiePopup.find('a.disabled').click(function() {
						return false;
					});

					// 'Accept All' handler
					$cookieAccept.click(function() {
						enforceCookiePolicy(saveCookiePolicy(true, true, true));
						closeCookiePopup();
						return false;
					});
					
					// 'Save & Close' handler
					$cookieSave.click(function() {
						enforceCookiePolicy(saveCookiePolicy($cookieGA.hasClass('active'), $cookieYT.hasClass('active')/*, $cookiePardot.hasClass('active')*/));
						closeCookiePopup();
						return false;
					});

					settingsFormOpen();
				});
			}
			else {
				// Display the previously loaded cookie form
				closeCookiePopup = loadModal('.cookie-modal', popupClosed);
				settingsFormOpen();
			}

			return false;

			// Called each time the popup is opened
			function settingsFormOpen() {
				// Update the loaded policy
				policyCookie = getCookiePolicy();

				// Set the initial toggle selections. Show the services enabled if there is no existing policy.
				setToggle($cookieGA, !policyCookie || policyCookie.ga);
				setToggle($cookieYT, !policyCookie || policyCookie.yt);
//				setToggle($cookiePardot, !policyCookie || policyCookie.pardot);
				
				$cookieAccept.focus();	// Focus on 'Accept All'
			}

			// Closed via button or pressing escape
			function popupClosed() {
				$cookieBanner.toggle(!policyCookie);		// Show if the policy was not set
			}
		});
		
		function getCookiePolicy() {
			let data = Cookies.get('viewed_cookie_policy');
			return data ? JSON.parse(data) : null;
		}
		
		function saveCookiePolicy(useGA, useYT/*, usePardot*/) {
			let policy = {
				ga: useGA,
				yt: useYT/*,
				pardot: usePardot*/
			};

			// Save the cookie choice
			Cookies.set('viewed_cookie_policy', JSON.stringify(policy), { expires:365, path:'/' });

			return policy;
		}

		// This function activates the cookie policy
		let gaLoaded/*, pardotLoaded*/;
		function enforceCookiePolicy(newPolicy) {
			useGA = newPolicy && newPolicy.ga;
			useYT = newPolicy && newPolicy.yt;
//			usePardot = newPolicy && newPolicy.pardot;

			policyCookie = newPolicy;
			
			// Enable or disable Google Analytics tracking on this site
//			window['ga-disable-UA-169408905-1'] = !useGA;
			window['ga-disable-G-KK44GSJ4FN'] = !useGA;

			// Load Google Analytics only once
			if (useGA && !gaLoaded) {
				gaLoaded = true;
				
/*				(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
				(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
				m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
				})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

				ga('create', 'UA-169408905-1', 'auto');
				ga('send', 'pageview');*/
				
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'G-KK44GSJ4FN');				
			}

			// Activate/deactivate YouTube videos
			const ytWithCookie = 'www.youtube.com';
			const ytNoCookie = 'www.youtube-nocookie.com';
			const ytFrom = useYT ? ytNoCookie : ytWithCookie;
			const ytTo = useYT ? ytWithCookie : ytNoCookie;
			$('iframe').each(function()
			{
				let $frame = $(this),
					src = $frame.attr('src');
				if (src && src.indexOf(ytFrom)!==-1)
					$frame.attr('src', src.replace(ytFrom, ytTo));
			});

/*			// Load Pardot only once
			if (usePardot && !pardotLoaded) {
				pardotLoaded = true;

				window.piAId = '895761';
				window.piCId = '1917';
				window.piHostname = 'pi.pardot.com';

				var s = document.createElement('script'); s.type = 'text/javascript'; s.async = 1;
				s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
				var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
			}*/
		}

		// Set a toggle buttons state
		function setToggle($b, state) {
			$b.toggleClass('active', state).text(state ? 'on' : 'off');
		}

		// Toggle button click handler
		function onToggle(e) {
			let $b = $(e.target);
			setToggle($b, !$b.hasClass('active'));
			return false;
		}
	})();


	// Social sharing popup with a close button
    $('.socialshare__btn > a').click(function() {
		let $btn = $(this).addClass('active');
		let $popup = $btn.next('.socialshare__popup');
		
		$popup.find('.socialshare__popup__close').one('click', function() {
			$popup.attr('aria-expanded', false).hide();
			$btn.removeClass('active').focus();
			return false;
		});
		
		$popup.attr('aria-expanded', true).show();
		return false;
	});


	// Image lazy loading. For modern browsers use loading="lazy", for old browsers use lazysizes.js.
	function lazyLoadImages($content) {
		if ('loading' in HTMLImageElement.prototype) {
			// loading="lazy" will already be set. Just replace the "data" placeholder urls.
			($content ? $content.find('img.lazyload') : $('img.lazyload')).each((i, q) => {
				f(q, 'data-src', 'src');
				f(q, 'data-srcset', 'srcset');
			});
		}

		function f(q, s, d) {
			let v = q.getAttribute(s);
			if (v) {
				q.setAttribute(d, v);
				q.removeAttribute(s);
			}
		}
	}
	
	lazyLoadImages();
	
	const youtubeRegEx = /^https?:\/\/(?:(?:www\.)?youtube(?:\.|-)|m.youtube|youtu.be)/i;
	const youtubeId = /(?:\/|%3D|v=|vi=)([0-9A-z-_]{11})(?:[%#?&]|$)/i;
	
	$('a.video-popup').click(function(e) {
		e.preventDefault();

        let src = this.getAttribute('href');
		
		// Turn the supplied YouTube video into an embed link that is compatible with the cookie setting
		if (youtubeRegEx.test(src)) {
			let id = src.match(youtubeId)[1];
			src = (useYT ? 'https://www.youtube.com/embed/' : 'https://www.youtube-nocookie.com/embed/') + id + '?rel=0&showinfo=0&autoplay=1';
		}
		
		let $player;

		if ($(window).width() > 800) {
			$player = $('<div class="video-player-popup"><a href="#" aria-label="Close"><svg viewBox="0 0 32 32"><use xlink:href="/wp-content/themes/twenty20child/dist/sprites.svg#close"></use></svg></a><div><iframe width="100%" height="360" src="' + src + '" frameborder="0" allowfullscreen></iframe></div></div>');
			
			$player.click(close);
			$player.find('a').click(close);

			$player.appendTo('body');
			$('.video-player-popup').fadeIn(500);
		}
		else {
			window.location.href = src;
		}
		
		function close(e) {
			e.preventDefault();
			$player.fadeOut(500);
			$player.remove();
		}
	});
});
