jQuery(function($) {
	var captchaLoaded = false;

    //Load reCAPTCHA script when CF7 form field is focused
    $('.wpcf7-form input').on('focus', function() {
        // If we have loaded script once already, exit.
        if (captchaLoaded)
            return;

        // Set flag to only load once
        captchaLoaded = true;
		
        // Variable Intialization
        var head = document.getElementsByTagName('head')[0];
        var recaptchaScript = document.createElement('script');
		var wpcf7_recaptcha = window.wpcf7_recaptcha = {"sitekey":"6LeOYawZAAAAAC8ZtbZ_r2Xis-wG2P6zYcbDdoVb","actions":{"homepage":"homepage","contactform":"contactform"}};

        // Dynamically add Recaptcha Script
        recaptchaScript.type = 'text/javascript';
        recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?render=' + wpcf7_recaptcha.sitekey + '&#038;ver=3.0';
		recaptchaScript.onload = function () {
			
			// Extracted from plugins\contact-form-7\modules\recaptcha\script.js
			// Remove DOMContentLoaded event

			wpcf7_recaptcha.execute = function( action ) {
				window.grecaptcha.execute(
					wpcf7_recaptcha.sitekey,
					{ action: action }
				).then( function( token ) {
					var event = new CustomEvent( 'wpcf7grecaptchaexecuted', {
						detail: {
							action: action,
							token: token,
						},
					} );

					document.dispatchEvent( event );
				} );
			};

			wpcf7_recaptcha.execute_on_homepage = function() {
				wpcf7_recaptcha.execute( wpcf7_recaptcha.actions[ 'homepage' ] );
			};

			wpcf7_recaptcha.execute_on_contactform = function() {
				wpcf7_recaptcha.execute( wpcf7_recaptcha.actions[ 'contactform' ] );
			};

			window.grecaptcha.ready(
				wpcf7_recaptcha.execute_on_homepage
			);

			document.addEventListener( 'change',
				wpcf7_recaptcha.execute_on_contactform
			);

			document.addEventListener( 'wpcf7submit',
				wpcf7_recaptcha.execute_on_homepage
			);

		};
		
		document.addEventListener( 'wpcf7grecaptchaexecuted', function( event ) {
			var fields = document.querySelectorAll(
				"form.wpcf7-form input[name='_wpcf7_recaptcha_response']"
			);

			for ( var i = 0; i < fields.length; i++ ) {
				var field = fields[ i ];
				field.setAttribute( 'value', event.detail.token );
			}
		} );

        head.appendChild(recaptchaScript);
    });
});